import { Controller } from "@hotwired/stimulus"
import CSVBoxImporter from '@csvbox/csvboxjs';

export default class extends Controller {
  static targets = ["sheet"]

  importer;
  async connect() {
    console.log("CONNECTED CSVBOX");
    this.importer = await this.initializeImporter();
    // console.log("IMPORTER initialized:", this.importer);
  };

  async initializeImporter() {
    const callback = (result, data) => {
      if (result) {
        // notify_success("Sheet uploaded successfully, we will email you when the import is complete");
        console.log("Sheet uploaded successfully to csvbox");
        // console.log(data);
        console.log(`${data.row_success} rows uploaded`);
        this.closeSVPModal();
      } else {
        // notify_danger("There was some problem uploading the sheet, please try again");
        console.log("There was some problem uploading the sheet", data);
      }
    };

    const sheet = this.sheetTarget.dataset.value;
    const dataVars = JSON.parse(this.sheetTarget.dataset.vars);
    const accountID = this.sheetTarget.dataset.csvboxAccountIdValue;

    let resource='';
    let invitable_type;
    let invitable_id;
    let portal_slug;

    if(!sheet) {
      console.log("Sheet name is required");
      return;
    } else if(sheet === "booking_single_slot" || sheet === "booking_multi_slot") {
      resource = dataVars.resource; // Event slug
    } else if(sheet === "invites") {
      invitable_id = dataVars.invitable_id;
      invitable_type = dataVars.invitable_type;
    } else if (sheet === "contacts") {
      resource = dataVars.resource; // Org Id
    } else if (sheet === "portal_invites") {
      invitable_type = dataVars.invitable_type;
      invitable_id = dataVars.invitable_id;
      portal_slug = dataVars.portal_slug; // To find allocation
    }

    const apiKey = await this.fetchCredentials(sheet, resource, accountID, invitable_type, invitable_id, portal_slug);
    this.importer = new CSVBoxImporter(apiKey, {}, callback);

    this.importer.setUser({
      user_id: accountID,
      resource: resource,
      invitable_type: invitable_type,
      invitable_id: invitable_id,
      portal_slug: portal_slug,
    });

    return this.importer;
  };

  async fetchCredentials(sheetName, resource, account_id='', invitable_type='', invitable_id='', portal_slug='') {
    try {
      if (!sheetName) {
        console.log("Sheet name is required");
        return;
      } else if (sheetName === "booking_single_slot" || sheetName === "booking_multi_slot") {
        let response = await fetch(`/csvbox_credentials?sheet=bookings&event_id=${resource}&account_id=${account_id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          }
        });
        let data = await response.json();
        return data.credentials;
      } else if (sheetName === "invites") {
        let response = await fetch(`/csvbox_credentials?sheet=invites&account_id=${account_id}&invitable_type=${invitable_type}&invitable_id=${invitable_id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          }
        });
        let data = await response.json();
        return data.credentials;
      } else if (sheetName === "contacts") {
        let response = await fetch(`/csvbox_credentials?sheet=contacts&account_id=${account_id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          }
        });
        let data = await response.json();
        return data.credentials;
      } else if (sheetName === "portal_invites") {
        let response = await fetch(`/csvbox_credentials?sheet=invites&portal_slug=${portal_slug}&invitable_type=${invitable_type}&invitable_id=${invitable_id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          }
        });
        let data = await response.json();
        return data.credentials;
      } else {
        console.log("\n\n No sheet \n\n");
      };

    } catch (error) {
      console.log(error);
    }
  };

  async openImporter(e) {
    e.preventDefault();
    const loader = document.getElementById('csv-loader');
    if (loader) {
      loader.style.display = 'block';
    }
    if (this.importer) {
      await this.importer.openModal();
    }
    if (loader) {
      loader.style.display = 'none';
    }
  };


  closeSVPModal() {
    document.querySelectorAll(".modal").forEach(modal => modal.classList.remove("is-active"));
    document.querySelector("#svpmodal .modal-content").innerHTML = "";
  };

}
